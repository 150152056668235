.box{
    width: 48%;
    text-align: center;
    padding: 1em;
    box-shadow: 0 4px 17px rgba(24, 39, 75, 0.12);
    border-radius: 1.25em;
    margin-top: 3em;
    padding: 2.125em 2.125em 1.5em;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: center;
    background: var(--white);
    border: 3px solid var(--primary-green);
    @media(max-width: 59.375em){
        margin-top: 0;
        margin-bottom: 1em;
        padding: .7em;
        width: 100%;
        border: none;
        border-radius: 0;
        box-shadow: none;
        &:first-of-type{
            border-bottom: 2px solid #ECF0F3;
        }
    }
}
.price{
    margin-top: 1.2rem;
    font-size: 2.125em;
    color: var(--primary-green);
    span{
        display: inline-block;
        vertical-align: middle;
        font-size: 1.375rem ;
        margin: -.2em .2em 0 0;
    }
}
.period{
    margin-bottom: 1.5rem;
    display: block;
    font-weight: 600;
    font-size: 1em;
    color: #515151;
}
.description{
    width: 21.5em;
    margin: 0 auto;
}