
.area {
  position: relative;
  display: flex;
  align-items: center;
  height: 40.5em;
  width: 100%;
  overflow: hidden;
  background: var(--primary-green);
  margin: 0 0 0 0;
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    strong {
      font-weight: 900;
    }
    sup {
      font-weight: normal;
      font-size: 1rem;
    }
  }
  button {
    margin: 1.5em 0;
    a {
      color: var(--secondary-black);
    }
  }
  p {
    font-size: 1.25rem;
    margin: 2.3em 0;
    width: 90%;
  }
  .box {
    width: 48%;
  }
  & > div {
    position: relative;
  }

  @media (max-width: 59.375em) {
    margin: 3em 0;
    height: auto;
    padding: 2em 0 0em;
    flex-flow: column wrap;
    overflow: initial;
    p,
    button {
      font-size: 0.875em;
      position: absolute;
      z-index: 9999;
      width: 92%;
      a {
        color: var(--white);
      }
    }
    p {
      bottom: -125px;
    }
    button {
      padding: 1.5em 0;
      width: 92%;
      bottom: -200px;
      background: var(--primary-green);
      color: var(--white);
    }
    h1 {
      font-size: 4vw;
      margin-bottom: 1.5em;
    }
    .box {
      width: 100%;
    }
    & > div {
      flex-flow: column wrap;
    }

    margin-bottom: 200px;
  }
  @media (max-width: 33.125em) {
    h1 {
      font-size: 1.5em;
      margin-bottom: 1.5em;
    }
  }
  & > div {
    position: relative;
  }
}

.area2 {
  .centerText {
    justify-content: center;
    width: 757px;
    @media (max-width: 757px) {
      width: fit-content;
    }
  }
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--primary-green);
  margin-top: 6em;
  margin-bottom: 0;
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    strong {
      font-weight: 900;
    }
    sup {
      font-weight: normal;
      font-size: 1rem;
    }
  }
  button {
    //margin: 1.5em 0;
    a {
      color: var(--secondary-black);
    }
  }
  p {
    font-size: 1.25rem;
    margin: 2.3em 0;
    width: 90%;
  }
  .box {
    width: 48%;
  }
  & > div {
    position: relative;
  }

  @media (max-width: 59.375em) {
    margin: 3em 0;
    height: auto;
    padding: 2em 0 0em;
    flex-flow: column wrap;
    overflow: initial;
    p,
    button {
      font-size: 0.875em;
      position: absolute;
      z-index: 9999;
      width: 92%;
      a {
        color: var(--white);
      }
    }
    p {
      bottom: -125px;
    }
    button {
      padding: 1.5em 0;
      width: 92%;
      bottom: -150px;
      background: var(--primary-green);
      color: var(--white);
    }
    h1 {
      font-size: 4vw;
      //margin-bottom: 1.5em;
    }
    .box {
      width: 100%;
    }
    & > div {
      flex-flow: column wrap;
    }

    margin-bottom: 200px;
  }
  @media (max-width: 33.125em) {
    h1 {
      font-size: 1.5em;
      //margin-bottom: 1.5em;
    }
  }
  & > div {
    position: relative;
  }
}

.chevron {
  position: absolute;
  bottom: 0;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -10%);
  display: block;
  width: 30px;
  cursor: pointer;
  animation: animateChevron 1s infinite;
  @media (max-width: 59.375em) {
    width: 30px;
    svg {
      width: 20px;
    }
  }
}

@keyframes animateChevron {
  0% {
    bottom: 0;
  }
  25% {
    bottom: 0.3em;
  }
  50% {
    bottom: 0;
  }
  75% {
    bottom: 0.3em;
  }
  100% {
    bottom: 0;
  }
}

.motion {
  width: 50%;
  right: 0;
  bottom: -112px;
  position: absolute;
  // height: 550px;
  // overflow: hidden;
  canvas {
    display: none;
  }
  img {
    position: relative;
    z-index: 100;
    width: 100%;
    // height: 100%;
  }
  video {
    position: absolute;
    z-index: 0;
    pointer-events: none;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    animation: showDemo 0.2s ease-in infinite 0.3s;
    &::-webkit-media-controls-panel {
      display: none !important;
      opacity: 0 !important;
    }
  }
  video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 0 !important;
  }
  @media (max-width: 59.375em) {
    position: relative;
    // bottom: -87px;
    margin: 0 auto -0.4em;
    width: 100%;
    bottom: 0;
    video {
      height: auto;
      width: 100%;
    }
  }
  // @media(max-width: 33.125em){
  //     bottom: 0;
  // }
}

@keyframes showDemo {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
}
