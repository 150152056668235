@import '../../../scss/sources.scss';

.picture{
    position: relative;
    width: 10em;
    height: 10em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.775em 0;
    text-align: center;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(100%);
        @include efect(all, .5s, ease-out);
    }
    a{
        display: block;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--secondary-black);
        @include efect(all, .2s, ease-out);
        border: 4px solid transparent;
        &:hover,
        &:focus{
            color: var(--primary-green);
            margin-top: -1em;
            border: 4px solid var(--primary-green);
            img{
                filter: grayscale(0)
            }
        }
    }
    @media(max-width: 48em){
        width: 6em;
        height: auto;
    }
}
.picture figcaption{
    position: absolute;
    width: 100%;
    bottom: -2em;
    line-height: 1em;
    @media(max-width: 48em){
        font-size: .75em;
    }
}