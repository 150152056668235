.area{
    text-align: center;
    padding: 4.5em 1em 3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column; 
    

    h2{
        margin: 1em;
    }
    p{
        margin-bottom: 1em;
    }
    a{
        text-decoration: none;
    }
    @media(max-width: 48em){
        a{
            width: 100%;
        }
        button{
            width: 100% !important;
        }
    }
}