.footer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    height: auto;
    min-height: 17.25em;
    background: var(--dark-gray);
    a{
        color: var(--white);
        text-decoration: none;
        font-size: .875em;
        font-weight: 400;
        &:not(:first-of-type){
            margin-left: 3em;
        }
        &:hover,
        &:focus{
            text-decoration: underline;
        }
    }
    @media(max-width: 26.25em){
        .links{
            display: block;
            a{
                display: block;
                margin-bottom: 1em;
            }
        }
    }
}
.logo{
    margin-bottom: 3.625em;
    display: flex;
    align-items: start;
    color: var(--white);
}
