.modal{
    display: none;
    position: fixed;
    // display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.7);
    .area{
        background: var(--white);
        border-radius: 10px;
        overflow: hidden;
        section{
            padding: .7em;
            section{
                margin-bottom: 1em;
            }
        }
    }
    .lnkWithout{
        float: right;
        margin: 0 2.5em 2em;
        font-weight: bold;
        color: var(--primary-green);
        &:hover,
        &:focus{
            text-decoration: underline;
        }
    }
    .closed{
        float: right;
        margin: 2.5em;
        cursor: pointer;
    }
    @media(max-width: 71.25em){
        padding: 1em;
        .area{
            height: 95%;
            width: 100%;
            section{
                height: 90%;
                width: 100%;
                section{
                    overflow: scroll;
                    height: 80%;
                }
            }
        }
    }
    @media(max-width: 59.375em){
        .closed{
            margin: 1.5em;
        }
        h2{
            font-size: 1em;
        }
        .lnkWithout{
            font-size: 1em;
        }
    }
}