.wave{
    position: absolute;
    bottom: 270px;
    z-index: -1;
    width: 100%;
    height: 400px;
    background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                linear-gradient(to top, #E6F8F0 50%, #ffffff 50%);

    @media(max-width: 1247px){
        background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                    linear-gradient(to top, #E6F8F0 70%, #ffffff 30%);
    }
    @media(max-width: 710px){
        background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                    linear-gradient(to top, #E6F8F0 80%, #ffffff 20%);
    }
    @media(max-width: 445px){
        background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                    linear-gradient(to top, #E6F8F0 85%, #ffffff 10%);
        height: 320px;
    }
    // display: block;
    
}

.home{
    position: relative;
}
.paragraph{
    text-align: center;
}