.faqing{
    height: auto;
    position: relative;
    nav{
        li{
            &:nth-child(3){
                a{
                    color: var(--white);
                    &::after{
                        width: 55%;
                    }
                }
            }
        }
    }
    @media(max-width: 59.375em){
        nav{
            li{
                &:nth-child(3){
                    a{
                        color: var(--primary-green);
                        &::after{
                            width: 55%;
                            background: currentColor;
                        }
                    }
                }
            }
        }
    }
}
.questions{
    margin-bottom: 5em;
}
.priceTitle{
    text-align: center;
    margin-bottom: 2.5rem;
}
.promotion{
    display: flex;
    justify-content: space-between;
    @media(max-width: 59.375em){
        flex-flow: column wrap;
    }
}
.title{
    text-align: center;
    margin: 9.9375em 0 4.9375em;
    h1{    
        font-size: 2.125em;
        margin: 1rem 0;
        line-height: 1em;
        display: inline;
    }
    h2{
        margin-bottom: 3rem;
        color: #424242;
        display: inline;
    }
    @media (max-width: 59.375em){
        margin: 6.9375em 0 3.9375em;
    }
    @media(max-width: 48em){
        h1{
            font-size: 4vw;
        }
        h2{
            font-size: 3vw;
        }
    }
    @media(max-width: 33.125em){
        margin: 6.9375em 0 2.9375em;
        h1{
            font-size: 1.5em;
        }
        h2{
            font-size: 1.2em;
        }
    }
}
.box{
    width: 48%;
    background: var(--white);
    text-align: center;
    padding: 1em;
    box-shadow: 0 4px 17px rgba(24, 39, 75, 0.12);
    border-radius: 1.25em;
    margin-top: 3em;
    padding: 2.125em 2.125em 1.5em;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: center;
}
.price{
    margin-top: 1.2rem;
    font-size: 2.125em;
    color: var(--primary-green);
    span{
        display: inline-block;
        vertical-align: middle;
        font-size: 1.375rem ;
        margin: -.2em .2em 0 0;
    }
}
.period{
    margin-bottom: 1.5rem;
    display: block;
    font-weight: 600;
    font-size: 1em;
    color: #515151;
}
.description{
    width: 21.5em;
}
.disable{
    &>div,
    &>button{
        opacity: 0.5;
    }
    pointer-events: none;
    
    border: none;
    @media(max-width: 59.375em){
        button{
            display: none;
        }
    }
}
.headerInfoPromotion{
    display: flex;
    flex-flow: column wrap;   
    width: 100%;
    div{
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        padding-left: .5em;
    }
    @media(max-width: 59.375em){
        .price{ 
            margin-top: 0;
            margin: 0 .5em;
        }
        h2{ font-size: 1.35em }
        .period{ margin-bottom: 0 }
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        div{
            flex-flow: row wrap;
            justify-content: center;
            width: 80%;
        }
    }
}

.wave{
    position: absolute;
    top: -80px;
    z-index: -1;
    background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                linear-gradient(to top, #E6F8F0 80%, #ffffff 50%);

    @media(max-width: 1247px){
        background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                    linear-gradient(to top, #E6F8F0 80%, #ffffff 30%);
                    top: -100px;
    }
    @media(max-width: 710px){
        background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                    linear-gradient(to top, #E6F8F0 80%, #ffffff 20%);
                    top: -100px;
    }
    @media(max-width: 445px){
        background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                    linear-gradient(to top, #E6F8F0 95%, #ffffff 10%);
                    top: -100px;
    }
    // display: block;
    width: 100%;
    height: 800px;
    transform: rotate(180deg);
}

.wave2{
    position: absolute;
    bottom: 280px;
    z-index: -1;
    background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
    linear-gradient(to top, #E6F8F0 50%, #ffffff 50%);
    // display: block;
    width: 100%;
    height: 200px;
    @media(max-width: 1247px){
    background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
        linear-gradient(to top, #E6F8F0 70%, #ffffff 30%);
    }
    @media(max-width: 710px){
    background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
        linear-gradient(to top, #E6F8F0 80%, #ffffff 20%);
    }
    @media(max-width: 445px){
    background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
        linear-gradient(to top, #E6F8F0 90%, #ffffff 10%);
        height: 350px;
    }
}

.promotionBox{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: transparent;
    border-radius: 1.25em;
    overflow: hidden;
    padding-bottom: 1em;
    @media(max-width: 59.375em){
        flex-flow: column wrap;
        background: var(--white);
        box-shadow: 0 4px 17px rgba(24, 39, 75, 0.12);
    }
}
