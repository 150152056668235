@import '../../../../scss/sources.scss';


.question{
    position: relative;
    background: #ffffff;
    box-shadow: 0 4px 17px rgba(24, 39, 75, 0.12);
    border-radius: 20px;
    padding: 1.5em;
    margin: 1.5em 0;
    width: 100%;
    h3{
        position: relative;
        font-size: 1.125em;
        font-weight: 500;
        line-height: 1.6875em;
        cursor: pointer;
        padding-right: 1.5em;
    }
    p{
        height: 0;
        font-size: 1em;
        line-height: 1.5em;
        padding: 0;
        overflow: hidden;
        opacity: 0;
        @include efect(all, .2s, ease-out)
    }
    button{
        position: absolute;
        right: 0;
        top: 0.5em;
        background: none;
        border: none;
    }
    @media(max-width: 48em){
        button{
            // top: calc(100% - 2em);
            width: 15px;
            svg{
                width: 100%;
            }
        }
    }
    @media(max-width: 37.5em){
        h3{
            font-size: .875em;
            line-height: 1.7em;
        }
        p{
            font-size: .875em;
            line-height: 1.7em;
        }
    }
}