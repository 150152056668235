.areaInformation{
    display: flex;
    flex-flow: row nowrap;
    margin: 5em 0 10em;
    justify-content: center;
    h3{
        margin-bottom: 2rem;
    }
    @media(max-width: 43.75em){
        margin: 2em 0;
        flex-flow: column  wrap;
        h3{
            margin-bottom: .7em;
            line-height: 1.2em;
        }
    }
}
.figure{
    overflow: hidden;
    border-radius: 4px;
    background: #ccc;
    height: 26.75em;
    width: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media(max-width: 43.75em){
        order: 0;
        width: 80%;
        height: 10.75em;
        margin-bottom: 1em;
    }
}
.content1{
    padding: 0 100px;
    width: 60%;
    max-width: 52.1875rem;
    padding: 0 10% 0 2.0625em;
    @media(max-width: 43.75em){
        order: 1;
        width: 100%;
        padding: 0 20% 0 1.0625em;
    }
    
}
.content2{
    width: 60%;
    max-width: 52.1875rem;
    padding: 0 2.0625em 0 10%;
    @media(max-width: 43.75em){
        padding: 0 2.0625em 0 20%;
        order: 1;
        width: 100%;
    }
    
}
// .flexEnd{ align-self: flex-end }
// .flexStart{ align-self: flex-start }

.image1{
    display: flex;
    justify-content: flex-start;
    width: 50%;
    max-width: 610px;
    position: relative;
    &>img{
        z-index: -1;
        right: -6em;
        top: -6em;
        position: absolute;
    }
    @media(max-width: 43.75em){
        &>img{
            right: 0;
        }
        width: 100%;
    }
}

.image2{
    display: flex;
    width: 50%;
    max-width: 610px;
    // justify-content: flex-end;
    position: relative;
    &>img{
        z-index: -1;
        left: -6em;
        top: -6em;
        position: absolute;
    }
    @media(max-width: 43.75em){
        justify-content: flex-end;
        &>img{
            left: 0;
        }
        width: 100%;
    }
}