@import '../../scss/sources.scss';


.area{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    // height: 32vw;
    video{
        display: block;
        margin: 2em auto 3em;
    }
    @media(max-width: 38.25em){
        video{
            width: 100%;
        }
    }
}
.features{
    position: relative;
    margin: 9em 0 0;
    h1{
        font-size: 2.125em;
        margin: 3rem 0;
        text-align: center;
    }
    nav{
        li{
            &:first-of-type{
                a{
                    color: var(--white);
                    &::after{
                        width: 55%;
                    }
                }
                
            }
        }
    }
    @media(max-width: 59.375em){
        margin: 6em 0 0;
        nav{
            li{
                &:first-of-type{
                    a{
                        color: var(--primary-green);
                        &::after{
                            width: 55%;
                            background: currentColor;
                        }
                    }
                }
            }
        }
    }
}

.navigate{
    width: 27.7017544%;
    box-shadow: 0px 3px 14px rgba(24, 39, 75, 0.12);
    overflow: hidden;
    border-radius: 1.25em;
    background: var(--white);
    li{
        font-size: 1em;
        font-weight: 700;   
        line-height: 1.5em;
        cursor: pointer;
        a{
            text-decoration: none;
            color: var(--secondary-black);
            display: block;
            padding: 1em 1.5em;
            @include efect(all, .2s, ease-in-out);
            &:hover,
            &:focus,
            &:active{
                color: var(--primary-green);
                background: var(--water-green);
                em{
                    background-color: var(--primary-yellow);    
                    color: #ffffff;
                }
            }
            em{
                padding: .2em .5em;
                font-size: .75em;
                font-style: normal;
                font-weight: 600;
                border-radius: 8px;
                color: var(--primary-yellow);
                border: 1px solid var(--primary-yellow);
                @include efect(all, .2s, ease-in-out);
            }
            b,
            i{
                display: none;
                // border: 1px solid red;
            }
            
        }
    }
    @media(max-width: 59.375em){
        width: 100%;
        li{
            a{
                b,
                i{
                    margin-right: .7em;
                    text-indent: -9999px;
                    width: 1em;
                    height: 1em;
                    display: inline-block;
                    vertical-align: middle;
                    
                }
                b{ background: url(../../icons/arrow-left.svg) no-repeat center center / contain; }
                i{ 
                    display: none;
                    background: url(../../icons/closed-questions.svg) no-repeat center center / 75% 
                }
            }
        }
    }
}
.information{
    width: 69.2982456%;
    @media(max-width: 59.375em){
        display: none;
    }
}
.navigateContent{
    display: none;
    article{
        box-shadow: none;
    }
    @media(max-width: 59.375em){
        display: block;
        article{
            div{
                .text{
                    p{
                        font-weight: 400;
                    }
                }
            }
        }
    }
    @media(max-width: 38.75em){
        article{
            div{
                width: 100%;
                flex-flow: column wrap;
                .text{
                    h2{
                        display: none;
                    }
                    order: 1;
                    p{
                        font-weight: 400;
                    }
                }
                .image{
                    order: 0;
                    img{
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
.content{
    background: var(--white);
    height: 100%;
    border-radius: 1.25em;
    padding: 2.5em 2em;
    box-shadow: 0px 3px 14px rgba(24, 39, 75, 0.12);
    h2{
        margin-bottom: .4em;
        text-align: center;
    }
    &>div{
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
    }
}
.text{ 
    // width: 53%;
    em{
        display: inline-block;
        margin-bottom: 1.2em;
        padding: .2em .5em;
        font-size: .75em;
        font-style: normal;
        font-weight: 600;
        border-radius: 8px;
        color: var(--primary-yellow);
        border: 1px solid var(--primary-yellow);
    }
}
.image{
    //  width: 65%;
    //  margin: 2em auto 0;
     img{
         width: 100%;
         image-rendering: crisp-edges
     }
}
.activeFeature{
    color: var(--primary-green) !important;
    background: var(--water-green);
    em{
        background-color: var(--primary-yellow);    
        color: #ffffff !important;
    }
    b{ display: none !important }
    @media(max-width: 59.375em){
        i{
            display: inline-block !important;
        }
    }
}

.wave{
    position: absolute;
    bottom: 280px;
    z-index: -1;
    background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                linear-gradient(to top, #E6F8F0 70%, #ffffff 50%);

    @media(max-width: 1247px){
        background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                    linear-gradient(to top, #E6F8F0 70%, #ffffff 30%);
    }
    @media(max-width: 710px){
        background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                    linear-gradient(to top, #E6F8F0 80%, #ffffff 20%);
    }
    @media(max-width: 445px){
        background: url(../../icons/bg-waves.svg) repeat-x center 0 / contain,
                    linear-gradient(to top, #E6F8F0 90%, #ffffff 10%);
    }
    // display: block;
    width: 100%;
    height: 650px;
}

.comming{
    text-align: center;
}