@import '../../../scss/sources.scss';

.button{
    border-radius: 4px;
    font-size: 1em;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    margin: 1.5em auto;
    overflow: hidden;
    padding: .9em 1.2em;
    @media(max-width: 37.5em){
        width: 100%;
        padding: .9em .6em;
    }
}
.fullWhite{
    background: var(--white);
    padding: .875em 1em;
    @include efect(all, .1s, ease-out);
    &:hover,
    &:focus{
        transform: scale(1.03);
    }
}
.fullGreen{
    background: var(--primary-green);
    color: var(--white);
    @include efect(all, .2s, linear);
    &:hover,
    &:focus{
        filter: brightness(120%)
    }
    a{
        color: var(--white);
    }
}
.ghostGreen{
    background: transparent;
    color: var(--primary-green);
    border: 1px solid var(--primary-green);
    @include efect(all, .2s, linear);
    &:hover,
    &:focus{
        background-color: var(--primary-green);
        color: var(--white);
        a{
            color: var(--white);
        }
    }
    a{
        color: var(--primary-green);
    }
}