.area{
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    margin-bottom: 8.125em;
    @media(max-width: 48em){
        flex-flow: column wrap;
        margin-bottom: 0;
    }
    button{
        margin: -20em 0 0;
        @media(max-width: 48em){
            width: 100%;
            margin: 0 auto 1.5em;
        }
    }
}
.info{
    width: 40%;
    margin-bottom: 1em;
    @media(max-width: 48em){
        width: 100%;
    }
}

.discover{
    display: flex;
    flex-flow: row wrap;
    width: 60%;
    @media(max-width: 48em){
        width: 100%;
        justify-content: space-between;
    }
}
.redirect{
    text-decoration: none;
}
.link{
    @media(max-width: 48em){
        width: 100%;
    }
}
.elements{
    width: 25%;
    display: flex;
    margin: 0  0 1.875rem 1.875rem;
    @media(max-width: 48em){
        width: 45%;
        padding: 0;
        margin: 0;
        p{
            font-size: .75em;
        }
        img{
            width: 50%;
            margin: 1em 0 0;
        }
    }
}