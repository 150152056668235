@import '../../../scss/sources.scss';

.element{
    padding: 1.5em;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: space-between;
    color: var(--primary-green);
    font-weight: 700;
    border-radius: 1.25em;
    background: var(--water-green);
    text-align: center;
    p{ margin: 1em 0 }
    img{ @include efect(all, .1s, ease-out) }
    &:hover,
    &:focus{
        box-shadow: 0 0 15px rgba(0, 0, 0, .3);
        img{
            filter: contrast(150%);
        }
    }    

    @media(max-width: 48em){
        // width: 45%;
        padding: 0;
        margin: 0 0 1.875rem 0;
        p{
            font-size: .75em;
        }
        img{
            width: 50%;
            margin: 1em 0 0;
        }
    }

    @include efect(all, .3s, ease-out)

}