@import "../../scss/sources";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6em;
  z-index: 10000 !important;
  background: var(--primary-green);
  @media (max-width: 59.375em) {
    height: 3.875em;
    display: flex;
    align-items: center;
    svg {
      width: 12.5em;
    }
  }
  .logo {
    sup {
      margin-left: 0.3rem;
      position: relative;
      top: -1rem;
    }
  }
}
.area {
  background-color: var(--primary-green);
}
.menu {
  ul {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    li:not(:first-of-type) {
      margin-left: 2em;
    }
    li {
      position: relative;
      a {
        display: block;
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
  @media (max-width: 59.375em) {
    position: absolute;
    top: 3.875em;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: -2;
    padding: 1em;
    ul {
      flex-flow: column wrap;
      li {
        margin-left: 0 !important;
        margin-bottom: 1.6875em;
      }
    }
  }
}

.options {
  color: var(--dark-gree);
  @include efect(all, 0.1s, linear);
  &::after {
    content: "";
    height: 3px;
    background: var(--white);
    border-radius: 8px;
    display: block;
    width: 0;
    margin: 0 auto;
    @include efect(all, 0.1s, linear);
  }
  &:hover,
  &:focus {
    color: var(--white);
    &::after {
      width: 55%;
    }
  }
}
.ghost {
  color: var(--dark-gree);
  border: 2px solid var(--dark-gree);
  border-radius: 4px;
  padding: 0.4em 2em;
  @include efect(all, 0.2s, linear);
  &:hover,
  &:focus {
    border-color: var(--white);
    color: var(--white);
  }
}
.full {
  @include efect(all, 0.1s, linear);
  border-radius: 4px;
  background: var(--dark-gree);
  color: var(--white);
  padding: 0.5em 2em;
  a {
    color: var(--dark-gree);
  }
  &:hover,
  &:focus {
    background-color: var(--white);
    color: var(--dark-gree);
  }
}
.fixed {
  position: fixed;
  top: -10em;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
  @include efect(all, 0.2s, ease-in-out);
}
.efect {
  @include efect(all, 0.2s, ease-in-out);
  top: 0;
}

.responsiveMenu {
  display: none;
  @media (max-width: 59.375em) {
    display: block;
  }
}
.rMenu {
  @include efect(
    all,
    0.5s,
    cubic-bezier(0.01, 0.62, 0.02, 0.98)
  );
  margin-top: 0 !important;
}
.fallbackPricing {
  width: 257px;
  margin-left: -90px;
  margin-top: 0.5em;
  padding: 1em;
  border-radius: 20px;
  background: var(--white);
  position: absolute;
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
    0px 10px 32px -4px rgba(24, 39, 75, 0.1);
  display: none;
  z-index: 1000;
  b {
    color: var(--primary-green);
    font-weight: 500;
  }
}
