.area{
    padding: 6em 0 0;
    h2{
        text-align: center;
        margin-bottom: 4.5rem;
    }
    @media(max-width: 59.375em){
        padding: 2em 0 0;
        h2{
            text-align: left;
            line-height: 1.1em;
        }
    }
}
.box{
    display: block;
    margin-bottom: 3em;
    display: flex;
    span{
        width: 80%;
        display: block;
        b{
            font-size: 1.25em;
            line-height: 1em;
            margin-bottom: 15px;
            display: block;
        }
    }
    @media(max-width: 48.75em){
        span{
            font-size: .875em;
        }
    }
}
.list{
    width: 50%;
    @media(max-width: 48.75em){
        width: 100%;
    }
}
.image{
    width: 45%;
    position: relative;
    img{
        width: 100%;
        margin-bottom: 1.5em;
        border-radius: 8px;
    }
    @media(max-width: 48.75em){
        width: 100%;
    }
    .bgFeatures{
        width: 229px;
        height: 232px;
        z-index: -1;
        top: -100px;
        left: -100px;
        position: absolute;
    }
}
.number{
    width: 10%;
    border-radius: 2px;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.875em;
    font-weight: 700;
    // background: linear-gradient(to right top, var(--primary-green), #007181); 
    color: #ffffff;
    margin-right: 1em;
}
.information{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 48.75em){
        flex-flow: column wrap;
    }
}

.pointer1{
    background: #007181;
}
.pointer2{
    background: #007886;
}
.pointer3{
    background: #00907F;
}
.pointer4{
    background: #00A780;
}
.pointer5{
    background: #06BD69;
}