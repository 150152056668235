@import './scss/sources';
:root{
  --white: #fff;
  --primary-green: #00cb93;
  --secundary-green: #00A780;
  --dark-gree: #023D21;
  --water-green: #E6F8F0;
  --dark-gray: #494949;
  --secondary-black: #212121;
  --primary-yellow: #BDA70F;
}

*{
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  border: none;
  box-sizing: border-box;
  text-decoration: none;
}

body{
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  color: var(--secondary-black);
}

body,
html,
#root{ 
  // height: 100%;
  scroll-behavior: smooth;
}

.grid,
.grid2{
  width: 100%;
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
}
.grid{ max-width: 80em; }
.grid2{ max-width: 71.25em; }

.d-flex{ display: flex }
.space-between{ justify-content: space-between }
.align-center{ align-items: center }
.h100{ height: 100% }

.title{
  font-size: 2.125em;
  font-weight: 700;
  @media(max-width: 59.375em){
    font-size: 4vw;
  }
  @media(max-width: 530px){
    font-size: 1.5em;
  }
}
p{
  font-size: 1.125em;
  @media(max-width: 37.5em){
    font-size: .875em;
  }
}
.show-question{
  height: auto !important;
  padding: .5em 0 !important;
  margin-top: 1em !important;
  opacity: 1 !important;
}

@media(max-width: 59.375em){
  #responsiveMenu{
    box-shadow: 0px 3px 14px rgba(24, 39, 75, 0.12);
    margin-top: -120%;
    @include efect(all, .3s, ease-in-out);
    &:target{
        display: block;
    }
  }
}
.showModal{
  display: flex !important;
}