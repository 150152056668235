.area{
    text-align: center;
    padding-top: 5.5em;
    @media(max-width: 59.375em){
        text-align: left;
        padding-bottom: 1em;
        padding-top: 1em;
    }
}
.areaPictures{
    margin: 2.5em 4em 3.5em;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    @media(max-width: 59.375em){
        margin: 1em 0 3em;
    }
    figure{
        margin-left: 1em;
        margin-right: 1em;
    }
}