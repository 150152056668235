.area {
  padding: 1em;
  margin: 3em auto;
  border-radius: 1.25em;
  box-shadow: 0 4px 17px rgba(24, 39, 75, 0.12);
  background: #fff;
}
.title {
  font-size: 1.5em;
  text-align: center;
  margin: 1.7rem 0 2.5em;
  @media (max-width: 55.3125em) {
    margin: 0.7rem 0 1.5em;
    text-align: left;
  }
}
.list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  ul {
    width: auto;
    padding: 0 2.125em 1.5em;
    li {
      margin-bottom: 1.5em;
    }
    svg {
      display: inline-block;
      vertical-align: middle;
      width: 15px;
      height: 15px;
      margin-right: 0.5em;
    }
  }
  @media (max-width: 55.3125em) {
    ul {
      padding: 0;
      width: 100%;
      li {
        font-size: 0.875em;
      }
    }
  }
}
